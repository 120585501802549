.slide {
  position: relative;
}

.img-carousel {
  width: 100%; 
}

.text-carousel-centered {
 position: absolute;
 top: 50%;
 left: 20%;
 -webkit-transform: translate(-50%, -50%);
 transform: translate(-50%, -50%);
 color: white;
 font-size: 46px;
 -webkit-transition: all 300ms ease;
 transition: all 300ms ease;
 text-transform: uppercase;
}

.img-logo {
  width: 200px
}

.mh-346 {
  min-height: 346px;
}

.blog-photo {
  max-height: 209px;
}

.themeioan_event .course-viewer {
  min-height: 253px;
}

.mr-10 {
  margin-right:10px
}

.carousel-caption > h3 {
  color: orange;
}

.box-comitee {
    width: 100%;
    height: 200px;
    border: 1px solid #eee;
    padding-left: 10px;
    margin-top: 30px;
    margin-bottom: 50px;
}

.desc-people {
    padding-top: 20px;
}

.box-comitee p {
    font-size: 12px;
}

.social-people {
    background: #ff7149;
    width: 100%;
    height: 200px;
}

.social-people ul {
    margin-left: 10px;
    padding-left: 0px;
    padding-top: 5px;
}

.social-people li {
    list-style: none;
    padding: 10px;
    padding-bottom: 15px;
}

.img-people {
    width: 130px;
    height: 130px;
    border-radius: 100px;
    border: 2px solid #ff7149;
    margin: -40px auto;
    position: relative;
    margin-bottom: 5px;
}

.box-comitee h5 {
  font-size: 12pt;
}